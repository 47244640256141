<template>
  <div>
    <!-- 頁面: 管理者詳情(權限: 完成, 註解: 刪除, 優化: 無) -->
    <div>
      <user-view-info
        @refresh-data="refreshUserInfoData"
      />
    </div>

    <b-tabs
      pills
      lazy
    >
      <!-- tab: 基本資料 -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">基本資料</span>
        </template>
      </b-tab>

      <!-- tab: 工作階段 -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="AirplayIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">工作階段</span>
        </template>
      </b-tab>

      <!-- tab: 權限設定 -->
      <b-tab
        v-if="checkAuthAbility('user', 'API.Admin.User.Power')"
        active
      >
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="18"
            class="mr-50"
          />
          <span class="font-weight-bold">權限設定</span>
        </template>

        <user-view-auth
          @refresh-data="refreshUserInfoData"
        />
      </b-tab>

    </b-tabs>
  </div>
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import { BTabs, BTab } from 'bootstrap-vue'

import store from '@/store'
import UserViewInfo from './UserViewInfo.vue'
import useStoreModule from '../useStoreModule'
import { useUserViewGeneral } from '../useUser'
import router from '@/router'
import UserViewAuth from './UserViewAuth.vue'
import { usePublicFunction } from '@/libs/mixins/index'

export default {
  metaInfo() {
    return {
      title: this.title ? this.title : store.state['app-config'].app.appName,
      titleTemplate: `%s - ${store.state.app.titleTemplate}`,
    }
  },
  components: {
    BTabs,
    BTab,
    UserViewInfo,
    UserViewAuth,
  },
  mixins: [usePublicFunction],
  data() {
    return {
      tabIndex: 0,
      title: router.currentRoute.meta.pageTitle,
    }
  },
  setup() {
    const USER_ADMIN_STORE_MODULE_NAME = 'admin-user'

    if (!store.hasModule(USER_ADMIN_STORE_MODULE_NAME)) store.registerModule(USER_ADMIN_STORE_MODULE_NAME, useStoreModule)

    onUnmounted(() => {
      if (store.hasModule(USER_ADMIN_STORE_MODULE_NAME)) store.unregisterModule(USER_ADMIN_STORE_MODULE_NAME)
    })

    const userID = router.currentRoute.params.id

    const {
      isInfoBusy,
      generalData,
      getUserInfoData,
    } = useUserViewGeneral()

    const refreshUserInfoData = () => getUserInfoData({ user_id: userID })

    refreshUserInfoData()

    return {
      userID,
      isInfoBusy,
      generalData,
      getUserInfoData,
      refreshUserInfoData,
    }
  },
}
</script>
