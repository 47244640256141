import { ref, watch } from '@vue/composition-api'
import {
  useTableComponent, useCommenSettings, useAlert,
} from '@/libs/mixins/index'
import store from '@/store'

export const useUserSetting = () => {
  const {
    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
  } = useCommenSettings()

  const stateOptions = [{
    label: '停用', value: false,
  }, {
    label: '啟用', value: true,
  }]

  const ui = {
    stateList: [{
      false: '停用', true: '啟用',
    }, {
      false: 'light-secondary', true: 'light-success',
    }],
  }

  const getMetaListData = (...arg) => store.dispatch('api/getMetaList', ...arg)

  return {
    ui,
    stateOptions,

    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
    getMetaListData,
  }
}

export const useUserList = () => {
  const {
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    timeRange,
    refetchData,
  } = useTableComponent()

  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  const {
    syncObject,
  } = useCommenSettings()

  tableColumns.value = [
    {
      label: '#編號', key: 'id', sortable: true, searchable: false, select: true,
    },
    {
      label: '狀態', key: 'state', sortable: true, searchable: true, select: true,
    },
    {
      label: '管理者', key: 'name', sortable: true, searchable: true, select: true,
    },
    {
      label: '上次登入', key: 'last_login_ip', sortable: true, searchable: true, select: true,
    },
    {
      label: '創建日期', key: 'created_at', sortable: true, searchable: true, select: true,
    },
    {
      label: '動作', key: 'actions', searchable: true, select: true,
    },
  ]

  const isBusy = ref(false)
  const searchState = ref(null)
  const blankUserData = {
    id: null,
    ssid: null,
    account: null,
    image: null,
    name: null,
    last_login_at: null,
    last_login_ip: null,
    state: false,
    created_at: null,
    updated_at: null,
  }

  const setUserDelete = (...arg) => store.dispatch('admin-user/setUserDelete', ...arg)
  const setUserCreate = (...arg) => store.dispatch('admin-user/setUserCreate', ...arg)
  const setUserUpdate = (...arg) => store.dispatch('admin-user/setUserUpdate', ...arg)
  const setUserPasswordUpdate = (...arg) => store.dispatch('admin-user/setUserPasswordUpdate', ...arg)

  const getUserListData = (ctx, callback) => {
    // 整理filters
    let resolveFilters = ''

    if (searchState.value !== null) {
      resolveFilters = `state: ${searchState.value ? 1 : 0}`
    }

    store.dispatch('admin-user/getUserList', {
      search: searchQuery.value,
      limit: perPage.value,
      page: currentPage.value,
      sort: sortBy.value,
      order: isSortDirDesc.value ? 'desc' : 'asc',
      offset: dataMeta.value.from,
      range: timeRange.value,
      filters: resolveFilters,
      _: Date.now(),
    })
      .then(response => {
        const { data, total } = response.data.data
        const resolveData = data.map(item => {
          const resolve = {
            ...syncObject(blankUserData, item),
          }
          return resolve
        })
        callback(resolveData)
        totalNum.value = total
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const refetchTable = () => {
    timeRange.value = null
    if (currentPage.value !== 1) {
      currentPage.value = 1
    } else refetchData()
  }

  watch([currentPage, perPage, searchQuery, searchState, timeRange], () => {
    refetchData()
  })

  return {
    isBusy,
    refDataListTable,
    perPage,
    totalNum,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    tableColumns,
    dataMeta,
    searchState,
    timeRange,
    blankUserData,
    refetchData,
    refetchTable,

    setUserDelete,
    setUserCreate,
    setUserUpdate,
    setUserPasswordUpdate,
    getUserListData,
    useAlertToast,
    useHttpCodeAlert,
  }
}

export const useUserViewGeneral = () => {
  const {
    syncObject,
    getMetaListData,
  } = useUserSetting()

  const {
    useHttpCodeAlert,
  } = useAlert()

  const {
    isBusy,
    blankUserData,
  } = useUserList()

  const blnkPowerData = {
    id: null,
    name: null,
    route_path: null,
    group: null,
    state: false,
  }

  const blankUserInfoData = {
    ...blankUserData,
    power: [],
  }

  const generalData = ref(null)
  const isLoadingBusy = ref(false)
  const groupPowerOptions = ref([])

  const getPowerListData = (filter, callback) => {
    isLoadingBusy.value = true
    getMetaListData({ ...filter })
      .then(response => {
        const { data } = response.data.data
        const powerOptions = data.map(item => {
          const resolve = {
            ...syncObject(blnkPowerData, item),
          }
          return resolve
        })
        store.commit('admin-user/UPDATE_POWER_LIST_STATE', powerOptions)
        isLoadingBusy.value = false
        callback()
      })
  }

  const getUserInfoData = (...args) => {
    store.commit('admin-user/UPDATE_BUSY_LOADING_STATE', true)
    store.dispatch('admin-user/getUserData', ...args)
      .then(response => {
        const { data } = response.data
        const resolveData = {
          ...syncObject(blankUserInfoData, data),
        }
        if (data.power) resolveData.power = data.power.split('.').map(item => parseInt(item, 10))
        store.commit('admin-user/UPDATE_USER_INFO_STATE', resolveData)
        store.commit('admin-user/UPDATE_BUSY_LOADING_STATE', false)
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  const setUserUpdate = (...args) => store.dispatch('admin-user/setUserUpdate', ...args)
  const setUserPowerUpdate = (...arg) => store.dispatch('admin-user/setUserPowerUpdate', ...arg)
  const setUserImageUpload = (...args) => store.dispatch('admin-user/setUserImageUpload', ...args)

  return {
    getPowerListData,
    getUserInfoData,
    setUserUpdate,
    setUserPowerUpdate,
    setUserImageUpload,
    isBusy,
    isLoadingBusy,
    blankUserInfoData,
    generalData,
    groupPowerOptions,
  }
}
