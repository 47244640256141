<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <b-card-title>
          權限列表
        </b-card-title>

        <div
          v-if="!adminUserState.isBusyLoading && !isLoadingBusy"
          class="text-nowrap d-flex justify-content-end"
        >
          <div
            v-b-tooltip.hover.focus.v-secondary
            title="重新整理"
            class="d-flex align-items-center actions-link-btn ml-25"
            @click="initPowerSettingList"
          >
            <b-img
              src="/dashboard/admin/images/table/refresh.svg"
              class="actions-link-btn-image"
            />
          </div>

          <div
            v-b-tooltip.hover.focus.v-secondary
            title="儲存"
            class="d-flex align-items-center actions-link-btn ml-25"
            @click="submitUpdateUserInfo"
          >
            <b-img
              src="/dashboard/admin/images/table/save.svg"
              class="actions-link-btn-image"
            />
          </div>
        </div>
      </b-card-header>

      <b-overlay
        :show="isBusy"
        variant="transparent"
        opacity="0.85"
        rounded="sm"
        blur="1px"
      >

        <div v-if="!adminUserState.isBusyLoading && !isLoadingBusy">
          <b-table-simple
            responsive
            striped
            bordered
            table-class="table-user-auth"
          >
            <b-thead>
              <b-tr>
                <b-th class="text-right">
                  群組
                </b-th>

                <b-th class="text-center">
                  權限
                </b-th>
              </b-tr>
            </b-thead>

            <b-tbody
              v-for="(group, index) in groupPowerOptions"
              :key="`group-${index}`"
            >
              <b-tr>
                <b-td class="text-right">
                  <label class="checkbox-label">
                    <div class="d-flex align-items-center">
                      <div class="mr-50 w-100">
                        <div class="table-title font-weight-bold">
                          {{ group.name }}
                        </div>
                      </div>

                      <b-form-checkbox
                        v-model="group.value"
                        name="table-column"
                        @change="changeGroupValue($event, index)"
                      />
                    </div>
                  </label>
                </b-td>

                <b-td>
                  <div>
                    <b-row>
                      <b-col
                        v-for="item in group.options"
                        :key="item.id"
                        cols="12"
                        xl="3"
                        lg="4"
                        md="6"
                        class="my-50"
                      >
                        <label class="checkbox-label">
                          <div class="d-flex align-items-center">
                            <b-form-checkbox
                              v-model="item.value"
                              name="table-column"
                            />

                            <div class="ml-50 w-100">
                              <div
                                class="d-block text-nowrap show-text"
                              >
                                {{ item.name }}
                              </div>
                              <small class="text-muted">
                                <div class="email-text">
                                  {{ item.route_path }}
                                </div>
                              </small>
                            </div>
                          </div>
                        </label>
                      </b-col>
                    </b-row>
                  </div>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>

        <b-card-body v-else>
          <div class="loading-area">
            <b-img
              :src="$store.state.app.themeImages.loadingImg"
              rounded
              height="60"
              width="60"
            />
          </div>
        </b-card-body>

      </b-overlay>

      <b-card-body class="pt-0">
        <div
          v-if="!adminUserState.isBusyLoading"
          class="w-100 text-right"
        >
          <b-button
            variant="outline-secondary"
            :disabled="isBusy"
            class="mr-1"
            @click="submitSelectedAll"
          >
            <span>{{ seletedState ? '全選' : '清空' }}</span>
          </b-button>

          <b-button
            variant="primary"
            :disabled="isBusy"
            @click="submitUpdateUserInfo"
          >
            <span v-if="!isBusy">儲存</span>

            <b-spinner
              v-else
              small
            />
          </b-button>
        </div>
      </b-card-body>
    </b-card>

  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BTableSimple, BTr, BTd, BTh, BTbody, BThead, BImg,
  BFormCheckbox, BRow, BCol, VBTooltip, BButton, BSpinner, BCardBody, BOverlay,
} from 'bootstrap-vue'
import { useUserViewGeneral } from '../useUser'
import { useSwalToast, useAlert, usePublicData } from '@/libs/mixins/index'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BButton,
    BSpinner,
    BTableSimple,
    BOverlay,
    BTr,
    BTd,
    BTh,
    BTbody,
    BThead,
    BCard,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BFormCheckbox,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  data() {
    return {
      seletedState: false,
    }
  },
  computed: {
    adminUserState() {
      return this.$store.state['admin-user']
    },
  },
  watch: {
    'adminUserState.userInfo': {
      handler() {
        this.initPowerSettingList()
      },
      deep: true,
    },
  },
  mounted() {
    if (this.adminUserState.userInfo) {
      this.initPowerSettingList()
    }
  },
  methods: {
    // (初始化)權限
    initPowerSettingList() {
      this.getPowerListData({ key: 'power', queryParams: { noPagination: true } }, () => {
        const resolveGroupPowerOptions = Object.values(this.adminUserState.powerList.reduce((acc, item) => {
          if (!acc[item.group]) {
            acc[item.group] = { name: item.group, options: [], value: false }
          }
          acc[item.group].options.push({
            ...item,
            value: this.adminUserState.userInfo.power.includes(item.id),
          })
          return acc
        }, {}))

        const sortedArray = resolveGroupPowerOptions.sort((a, b) => {
          const indexA = Object.keys(this.routerGroupKey).indexOf(a.name)
          const indexB = Object.keys(this.routerGroupKey).indexOf(b.name)
          return indexA - indexB
        })

        this.groupPowerOptions = sortedArray.map(group => ({
          ...group,
          value: group.options.every(option => option.value),
        }))
        this.seletedState = !this.groupPowerOptions.every(group => group.value)
      })
    },

    // (全選)所有權限
    submitSelectedAll() {
      this.groupPowerOptions = this.groupPowerOptions.map(group => ({
        ...group,
        value: this.seletedState,
        options: group.options.map(option => ({
          ...option,
          value: this.seletedState,
        })),
      }))
      this.seletedState = !this.seletedState
    },

    // (全選/取消)
    changeGroupValue(event, index) {
      this.groupPowerOptions = this.groupPowerOptions.map((group, groupIndex) => {
        if (groupIndex === index) {
          return {
            ...group,
            options: group.options.map(option => ({
              ...option,
              value: event,
            })),
          }
        }
        return group
      })
    },

    // (觸發)儲存
    submitUpdateUserInfo() {
      if (this.isBusy) return
      this.useSwalAlertWarning('變更權限', '你確定要變更該管理者的權限設定嗎?')
        .then(result => {
          if (result.value) {
            const openPowerIds = this.groupPowerOptions.flatMap(group => {
              const resolveData = group.options.filter(option => option.value).map(option => option.id)
              return resolveData
            })

            const resolveData = {
              power: openPowerIds.join('.'),
            }

            this.isBusy = true
            this.setUserPowerUpdate({
              user_id: this.adminUserState.userInfo.id,
              data: {
                ...resolveData,
              },
            })
              .then(response => {
                this.useHttpCodeAlert(response)
                const power = response.data.data

                const resolveUserInfo = {
                  ...this.adminUserState.userInfo,
                }
                resolveUserInfo.power = power.split('.').map(item => parseInt(item, 10))
                this.$store.commit('admin-user/UPDATE_USER_INFO_STATE', resolveUserInfo)
                this.isBusy = false
              })
              .catch(error => {
                this.useHttpCodeAlert(error.response)
                this.isBusy = false
              })
          }
        })
    },
  },
  setup() {
    const {
      isBusy,
      isLoadingBusy,
      getPowerListData,
      setUserPowerUpdate,
      groupPowerOptions,
    } = useUserViewGeneral()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const {
      routerGroupKey,
    } = usePublicData()

    return {
      isBusy,
      isLoadingBusy,
      groupPowerOptions,
      getPowerListData,
      setUserPowerUpdate,

      useAlertToast,
      useHttpCodeAlert,
      routerGroupKey,
    }
  },
}
</script>

<style lang="scss" scoped>
.table-user-auth {
  tbody {
    border-width: 1px;
  }

  .checkbox-label {
    font-size: 14px;
    cursor: pointer;
  }
}
</style>
